/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '../../molecules/textfield';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { Autocomplete } from '@material-ui/lab';

interface Props {
  open: boolean;
  onClose(): void;
}

const CouponCheckout: React.FC<Props> = ({ open, onClose }) => {
  const [stores, setStores] = useState([]);
  const [coupons, setCoupons] = useState([]);


  const formik = useFormik({
    initialValues: {
      cupom: '' as any,
      loja: '' as any
    },
    validationSchema: Yup.object().shape({
      cupom: Yup.object().required('Campo obrigatório!'),
      loja: Yup.object().required('Campo obrigatório!').typeError('Campo obrigatório!')
    }),
    onSubmit: async (values) => {
      try {
        await api.post('cupom/desativar', {
          id_cupom: values.cupom.value,
          id_loja: values.loja.value
        })

        toast.success('Cupom desativado com sucesso.')

        handleClose()
      } catch (error) {
        const message = error.response?.data?.message || 'Erro ao dar baixa em cupom!';
        toast.error(message)
      }
    }
  });

  function handleClose() {
    onClose();
    formik.setValues({
      cupom: '',
      loja: ''
    });
  }

  async function requestCoupons() {
    try {
      const { data } = await api.get(`cupons?id_loja=${formik.values.loja.value}&status=1`);
      setCoupons(data.map((cupom: any) => ({
        value: cupom.id,
        label: `${cupom.codigo_promocional} / ${cupom.descricao}`
      })));
    } catch (error) {
      const message = error.response?.data?.message || 'Erro ao solicitar cupons!';
      toast.error(message)
    }
  }

  useEffect(() => {
    formik.setErrors({})
    if(open) {
      requestStores();
    }
  }, [open])

  useEffect(() => {
    if(formik.values.loja) {
      requestCoupons()
    }
  }, [formik.values.loja])

  function formikProps(fieldName: 'cupom' | 'loja') {
    const error = (formik.touched[fieldName] ? formik.errors[fieldName] : undefined) as string;

    return {
      value: formik.values[fieldName],
      error
    };
  }

  async function requestStores() {
    try {
      const {data} = await api.get('lojas');
      setStores(data.map((loja: any) => ({ value: loja.id, label: loja.nome })));
    } catch (error) {
      const message = error.response?.data?.message || 'Erro ao solicitar lojas!';
      toast.error(message)
    }
  }

  function handleAutocompleteChange(field: string, option: any) {
    const value = option;

    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  } 

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>Desativação de cupom</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              style={{
                minWidth: 330
              }}
              options={stores}
              getOptionLabel={(option: any) => option && option.label}
              noOptionsText="Sem opções"
              value={formik.values.loja}
              onChange={(event: any, option: any) => handleAutocompleteChange('loja', option)}
              renderInput={params => (
                <TextField 
                  {...params}
                  name="loja"
                  label="Loja"
                  fullWidth
                  {...formikProps('loja')} 
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
           <Autocomplete
              options={coupons}
              getOptionLabel={(option: any) => option && option.label}
              noOptionsText="Sem opções"
              value={formik.values.cupom}
              onChange={(event: any, option: any) => handleAutocompleteChange('cupom', option)}
              renderInput={params => (
                <TextField 
                  {...params}
                  name="cupom"
                  label="Cupom"
                  fullWidth
                  {...formikProps('cupom')} 
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button variant="contained" onClick={() => formik.handleSubmit()}>Enviar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CouponCheckout;