import React, { useRef, useState } from "react";

import MaterialTable from "../../../molecules/table";

import { Container } from "./styles";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { format } from "date-fns";

const CheckoutCouponListPage: React.FC = () => {
  const [columns] = useState([
    { title: "Loja", field: "cupom.loja.nome" },
    { title: "Cupom", field: "cupom.codigo_promocional" },
    { title: "Nome do Cliente", field: "usuario_mobile.nome" },
    { title: "Nome do Usuário", field: "usuario.nome", hidden: true },
    { title: "Data de resgate", field: "data_resgate" },
    { title: "Data da baixa", field: "data_baixa" },
    {
      title: "Status",
      field: "status",
      lookup: {
        RES: "Reservado",
        BXD: "Baixado",
        EXP: "Expirado",
        CAN: "Cancelado"
      },
    },
  ]);

  const tableRef = useRef(null) as any;

  function filtersToQueryParams(filters: any) {
    const query = filters.map((filter: any) => {
      return `${filter.column.field}=${filter.value}`;
    });
    return query.join("&");
  }

  async function getData(query: any): Promise<any> {
    if (!query) return false;

    let params = "";
    let response;

    if (query.filters.length > 0) {
      params += `${filtersToQueryParams(query.filters)}&`;
    }

    params += `perPage=${query.pageSize}`;
    params += `&currentPage=${query.page + 1}`;

    try {
      response = await api.get(`baixa_cupons?${params}`);

      const formattedData = response.data?.data?.map((item: any) => ({
        ...item,
        data_baixa: item.data_baixa ? format(new Date(item.data_baixa), "dd/MM/yyyy hh:mm") : null,
        data_resgate: format(new Date(item.data_resgate), "dd/MM/yyyy hh:mm"),
      }));

      const { page, total } = response.data;

      return {
        data: formattedData || [],
        page: page - 1,
        totalCount: Number(total),
      };
    } catch (err) {
      toast.error("Erro");
    }
  }

  return (
    <Container>
      <>
        <MaterialTable
          title="Baixa de Cupons"
          tableRef={tableRef}
          columns={columns}
          getData={(query: any) => getData(query)}
        />
      </>
    </Container>
  );
};

export default CheckoutCouponListPage;
