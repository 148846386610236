/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid, Button, FormControlLabel, Switch } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import { Container, FormContainer } from './styles';

import TextField from '../../../molecules/textfield';
import PageTitle from '../../../atoms/page-title';
import api from '../../../services/api';
import Loading from '../../../atoms/loading';

const UserRegisterPage: React.FC = () => {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory() as any;
  const params = useParams<{id: string}>();

  const formik = useFormik({
		initialValues: {
      nome: '',
      email: '',
      loja: {} as any,
      status: true
    },
		validationSchema: Yup.object().shape({
      nome: Yup.string().required('Campo obrigatório!'),
      email: Yup.string().required('Campo obrigatório!'),
      loja: Yup.object().required('Campo obrigatório!'),
      status: Yup.boolean().required('Campo obrigatório!'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const formattedValues = {
        ...values,
        id_loja: values.loja.value,
      }
      delete formattedValues.loja;

      try {
        if(params.id) {
          await api.put(`/lojistas/${params.id}`, formattedValues);
        } else {
          await api.post('/lojistas', formattedValues);
        }
        toast.success('Lojista salvo com sucesso!');
        history.goBack();
      } catch (error) {
        toast.error('Erro ao salvar!');
      }

      setLoading(false);
    }
	});

  function formikProps(
    fieldName: 'nome' | 'email' | 'status' | 'loja'
  ) {
    const error = (formik.touched[fieldName] ? formik.errors[fieldName] : undefined) as string;

    return {
      value: formik.values[fieldName],
      error
    };
  }

  function handleChange(event: any) {
    const value = event?.target.value;
    const field = event?.target.name;

    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  }

  function handleSwitchChange(event: any) {
    const value = event.target.checked;
    const field = event.target.name;

    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  }

  function handleAutocompleteChange(field: string, option: any) {
    const value = option;

    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  }

  async function setInitialValues() {
    try {
      const { data: stores } = await api.get('lojas');
      setStores(stores.map((item: any) => ({
        value: item.id,
        label: item.nome
      })))

      if(!params.id) return;

      const { data } = await api.get(`lojistas/${params.id}`)
      formik.setValues({
        ...data,
        loja: { value: data.loja.id, label: data.loja.nome },
      });
    } catch (error) {
    }
  }

  useEffect(() => {
    setInitialValues();
  }, [])

  return (
    <>
      <Container>
        <PageTitle label="Lojista"/>
        <FormContainer>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={12}>
              <TextField 
                name="nome" 
                label="Nome" 
                fullWidth 
                {...formikProps('nome')} 
                onChange={handleChange} 
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="email" 
                label="Email" 
                fullWidth 
                {...formikProps('email')} 
                onChange={handleChange} 
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={stores}
                getOptionLabel={(option: any) => option && option.label}
                value={formik.values.loja}
                onChange={(event: any, option: any) => handleAutocompleteChange('loja', option)}
                renderInput={params => (
                  <TextField 
                    {...params}
                    name="loja"
                    label="Loja"
                    fullWidth
                    {...formikProps('loja')} 
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel control={
                <Switch 
                  name="status" 
                  {...formikProps('status')} 
                  onChange={handleSwitchChange}
                  checked={formik.values.status}
                />
              } 
              label="Status" />
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => history.goBack()}>
                Voltar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => formik.handleSubmit()} disabled={loading}>
                Enviar
                {loading && <Loading />}
              </Button>
            </Grid>
          </Grid>
        </FormContainer>
      </Container>
    </>
  );
}

export default UserRegisterPage;