import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  html, body, #root {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  html {
    background-color: #f7f7f7;
    min-width: 1260px;
  }

  a {
    color: inherit;
    cursor: default;
    text-decoration: none;
  }

  .MuiDrawer-paperAnchorLeft {
    background: #75029F !important;
    color: #FFF !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #75029F !important;
  }
  .MuiInput-underline:after {
    border-color: #75029F !important;
  }
  .MuiButton-contained {
    background-color: #75029F !important;
    color: #FFF !important;

    &:hover {
      background-color: #66018a !important;
    }
  }
  &.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
    min-width: 1000px;
  }
`;
