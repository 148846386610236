import styled from 'styled-components';
import { Drawer as MDrawer, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 15px 15px 225px;
  min-height: 100%;

  box-sizing: border-box;

  .MuiPaper-root {
    padding: 15px;
  }
`;


export const ProfileContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  padding: 10px 0;

  margin-top: 8px;

  cursor: pointer;

  transition: background 200ms;

  & .MuiTypography-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
  }

  &:hover {
    background: rgba(0,0,0,0.1);
  }
`;

export const UserName = styled(Typography)`

`;

export const Drawer = styled(MDrawer)`
  .MuiCollapse-wrapper .MuiButtonBase-root {
    padding-left: 28px;
  }
`;