import styled from 'styled-components';
import { Paper, Button } from '@material-ui/core';

export const Container = styled(Paper)`
  max-width: 600px;
`;


export const FormContainer = styled.div`
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.img`
  width: 150px;
  height: 150px;

  background-color: #CCC;
`;

export const ImageEditButton = styled(Button).attrs({
  variant: 'contained'
})`
  && {
    margin-left: 15px;
  }
`;