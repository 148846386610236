/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Container,
  FormContainer,
  Image,
  ImageContainer,
  ImageEditButton,
} from "./styles";

import CropDialog from "../../../molecules/crop-dialog";
import TextField from "../../../molecules/textfield";

import { toast } from "react-toastify";
import PageTitle from "../../../atoms/page-title";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../services/api";

const BannerRegisterPage: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false);
  // const [loading, setLoading] = useState(false);

  const [imageBlob, setImageBlob] = useState();
  const [imagePreview, setImagePreview] = useState();

  const history = useHistory() as any;
  const params = useParams<{ id: string }>();

  const formik = useFormik({
    initialValues: {
      url_banner: "",
    },
    validationSchema: Yup.object().shape({
      url_banner: Yup.string().nullable(),
    }),
    onSubmit: async (values) => {
      // setLoading(true);

      try {
        const formattedValues = {
          ...values,
          imagem_base64: imageBlob,
        };

        if (params.id) {
          await api.put(`/banners/${params.id}`, formattedValues);
        } else {
          // await api.post("/banners", formattedValues);
        }
        toast.success("Banner salvo com sucesso!");
        history.goBack();
      } catch (error) {
        toast.error("Erro ao salvar!");
      }
      // setLoading(false);
    },
  });

  function handleDialogClose() {
    setShowDialog(false);
  }

  function handleDialogSubmit(blob: any) {
    if (blob) setImageBlob(blob);
    handleDialogClose();
  }

  function formikProps(
    fieldName:
      | "url_banner"
  ) {
    const error = (formik.touched[fieldName]
      ? formik.errors[fieldName]
      : undefined) as string;

    return {
      value: formik.values[fieldName],
      error,
    };
  }

  function handleChange(event: any) {
    const value = event?.target.value;
    const field = event?.target.name;

    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  }

  async function setInitialValues() {
    try {
      if (!params.id) return;

      const { data } = await api.get(`banners/${params.id}`);
      setImageBlob(data.imagem_base64);
      setImagePreview(data.imagem_base64);
      formik.setValues({
        ...data
      });
    } catch (error) {}
  }

  useEffect(() => {
    setInitialValues();
  }, []);

  return (
    <>
      <CropDialog
        onClose={handleDialogClose}
        open={showDialog}
        onSubmit={handleDialogSubmit}
        imageProps={{
          imagePreview,
          setImagePreview,
          imageBlob,
          setImageBlob,
        }}
      />
      <Container>
        <PageTitle label="Banner" />
        <FormContainer>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={12}>
              <ImageContainer>
                <Image src={imageBlob as any} crossOrigin="anonymous" />
                <ImageEditButton
                  onClick={() => {
                    setShowDialog(true);
                  }}
                >
                  Alterar imagem
                </ImageEditButton>
              </ImageContainer>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="url_banner"
                label="URL Banner"
                fullWidth
                {...formikProps("url_banner")}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => history.push("/listagem/banners")}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => formik.handleSubmit()}>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </FormContainer>
      </Container>
    </>
  );
};

export default BannerRegisterPage;
