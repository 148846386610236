export interface AuthState {
  is_signed: boolean;
  user: any;
  is_loading: boolean;
  token: string | null;
}

export const SET_LOADING = '@auth/set_loading';
export type SetLoadingPayload = boolean;
export interface SetLoading {
  type: typeof SET_LOADING;
  payload: SetLoadingPayload;
}

export const SIGN_IN_REQUEST = '@auth/sign_in_request';
export interface SignInRequestPayload {
  email: string;
  senha: string;
}
export interface SignInRequest {
  type: typeof SIGN_IN_REQUEST;
  payload: SignInRequestPayload;
}

export const SIGN_IN_SUCCESS = '@auth/sign_in_success';
export interface SignInSuccessPayload {
  user: any;
  token: string;
}
export interface SignInSuccess {
  type: typeof SIGN_IN_SUCCESS;
  payload: SignInSuccessPayload;
}

export const SIGN_OUT = '@auth/sign_out';
export interface SignOut {
  type: typeof SIGN_OUT;
}

export const SET_TOKEN = '@auth/set_token';
export interface SetToken {
  type: typeof SET_TOKEN;
}

export type AuthActions =
  | SignInRequest
  | SignInSuccess
  | SetLoading
  | SetToken
  | SignOut;
