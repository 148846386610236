import Axios from 'axios';

const api = Axios.create({
  baseURL: 'https://api.sohapp.com.br/sohapp/api/v1/web'
})

export const interceptor = {
  id: null as any
}

export default api;