import {
  SetLoadingPayload,
  SET_LOADING,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  SetLoading,
  SignInRequest,
  SignInRequestPayload,
  SignInSuccess,
  SignInSuccessPayload,
  SignOut,
  SetToken,
  SET_TOKEN,
} from './types';

export function setLoading(payload: SetLoadingPayload): SetLoading {
  return {
    type: SET_LOADING,
    payload,
  };
}

export function signInRequest(payload: SignInRequestPayload): SignInRequest {
  return {
    type: SIGN_IN_REQUEST,
    payload,
  };
}

export function signInSuccess(payload: SignInSuccessPayload): SignInSuccess {
  return {
    type: SIGN_IN_SUCCESS,
    payload,
  };
}

export function signOut(): SignOut {
  return {
    type: SIGN_OUT,
  };
}

export function setToken(): SetToken {
  return {
    type: SET_TOKEN,
  };
}
