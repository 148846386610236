import React, { useRef } from 'react';

import MaterialTable from '../../../molecules/table';

import { Container } from './styles';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { toast } from 'react-toastify';

const BannerListPage: React.FC = () => {
  const history = useHistory();
  const tableRef = useRef(null) as any;

  const handleEditClick = (event: any, row: any) => {
    history.push(`/cadastro/banner/${row.id}`)
  }

  function filtersToQueryParams(filters: any) {
		const query = filters.map((filter: any) => {
			return `${filter.column.field}=${filter.value}`;
		});
		return query.join('&');
	}

	async function getData(query: any): Promise<any> {
    if (!query) return false;

		let params = '';
		let response;

    if (query.filters.length > 0) {
			params += `${filtersToQueryParams(query.filters)}&`;
    }
    
		params += `perPage=${query.pageSize}`;
		params += `&currentPage=${query.page + 1}`;

		try {
      response = await api.get(`banners?${params}`);

      const { page, total } = response.data;
    
			return {
				data: response.data.data || [],
				page: page - 1,
				totalCount: Number(total),
			};
		} catch (err) {
      toast.error('Erro');
		}
  }

  return (
    <Container>
      <>
        <MaterialTable 
          title="Banners"
          tableRef={tableRef}
          actions={[
            {
              icon: 'edit',
              onClick: handleEditClick
            },
          ]}
          columns={[
            { title: 'URL Banner', field: 'url_banner' },
          ]}
          getData={(query: any) => getData(query)}
        />
      </>
    </Container>
  );
}

export default BannerListPage;