import React from 'react';

import { Container, Image } from './styles';

const HomePage: React.FC = () => {
  return (
    <Container>
       <Image src={require('../../assets/images/lagoa_verde.png')} />
    </Container>
  );
}
export default HomePage;