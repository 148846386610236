import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Layout from '../../organisms/layout';
import { RootState } from '../../store';

interface RouteWrapperProps {
  component: React.FC<any>,
  isPrivate?: boolean;
  path: string;
  exact?: boolean;
}

const RouteWrapper: React.FC<RouteWrapperProps> = ({ component: Component, isPrivate, path, exact=true, ...rest }) => {
  const is_signed = useSelector((state: RootState) => state.auth.is_signed);

  if(!is_signed && isPrivate) return <Redirect to="/login" />
  if(!isPrivate && is_signed) return <Redirect to="/home" />

  const LayoutComponent: React.FC = ({children}) => {
    return is_signed ? <Layout>{children}</Layout> : <>{children}</>;
  }

  return (
    <Route {...rest} render={(props) => (
      <LayoutComponent>
        <Component {...props} />
      </LayoutComponent>
    )} />
  );
}

export default RouteWrapper;