import React from 'react';
import { Provider } from 'react-redux';

import './assets/styles/normalize.css';
import 'react-toastify/dist/ReactToastify.css';

import GlobalStyles from './assets/styles/global';

import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';

import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter basename="/administrador">
          <ToastContainer />
          <GlobalStyles />
          <Routes />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
