import React, { useRef, useState } from 'react';

import MaterialTable from '../../../molecules/table';

import { Container } from './styles';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import DeleteDialog from '../../../molecules/delete-dialog';

const CouponListPage: React.FC = () => {
  const history = useHistory();
  const tableRef = useRef(null) as any;

  const [rowToDelete, setRowToDelete] = useState(0);

  const handleAddClick = () => {
    history.push('/cadastro/cupom/');
  }

  const handleEditClick = (event: any, row: any) => {
    history.push(`/cadastro/cupom/${row.id}`)
  }

  function filtersToQueryParams(filters: any) {
		const query = filters.map((filter: any) => {
			return `${filter.column.field}=${filter.value}`;
		});
		return query.join('&');
	}

	async function getData(query: any): Promise<any> {
    if (!query) return false;

		let params = '';
		let response;

    if (query.filters.length > 0) {
			params += `${filtersToQueryParams(query.filters)}&`;
    }
    
		params += `perPage=${query.pageSize}`;
		params += `&currentPage=${query.page + 1}`;

		try {
      response = await api.get(`cupons?${params}`);
      
      const formattedData = response.data?.data?.map((item: any) => ({
        ...item,
        data_validade: format(new Date(item.data_validade), "dd/MM/yyyy hh:mm")
      }))

      const { page, total } = response.data;
    
			return {
				data: formattedData || [],
				page: page - 1,
				totalCount: Number(total),
			};
		} catch (err) {
      toast.error('Erro');
		}
  }

  // function handleOpenDialog(id: any) {
  //   setRowToDelete(id);
  // }
  
  function handleDialogClose() {
    setRowToDelete(0);
  }
  
  async function handleDeleteRow() {
    try {
      await api.delete(`cupons/${rowToDelete}`);
      tableRef.current && tableRef.current.onQueryChange();
      handleDialogClose();
    } catch (error) {
      const message = error.response?.data?.message || 'Erro ao tentar apagar dado!'
      toast.error(message);
    }
  }

  return (
    <Container>
      <>
        <DeleteDialog open={Boolean(rowToDelete)} onClose={handleDialogClose} onSubmit={handleDeleteRow} />
        <MaterialTable 
          title="Cupons"
          tableRef={tableRef}
          actions={[
            {
              isFreeAction: true,
              icon: 'add',
              onClick: handleAddClick
            },
            {
              icon: 'edit',
              onClick: handleEditClick
            },
          ]}
          columns={[
            { title: 'Descrição', field: 'descricao' },
            { title: 'Loja', field: 'loja.nome'},
            { title: 'Data de validade', field: 'data_validade' },
            { title: 'Quantidade disponível', field: 'quantidade_disponivel', type: 'numeric' },
          ]}
          getData={(query: any) => getData(query)}
        />
      </>
    </Container>
  );
}

export default CouponListPage;