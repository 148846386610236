import produce from 'immer';

import {
  AuthState,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  SIGN_IN_REQUEST,
  AuthActions,
  SET_LOADING,
} from './types';

const INITIAL_STATE: AuthState = {
  is_loading: false,
  is_signed: false,
  token: null,
  user: undefined
};

export default function auth(state = INITIAL_STATE, action: AuthActions): AuthState {
  return produce(state, (draft) => {
    switch (action.type) {
      case SIGN_IN_REQUEST: {
        draft.token = null;
        draft.is_loading = true;

        break;
      }
      case SIGN_IN_SUCCESS: {
        draft.is_loading = false;
        draft.token = action.payload.token;
        draft.is_signed = true;
        draft.user = action.payload.user;

        break;
      }
      case SIGN_OUT: {
        draft.is_signed = false;
        draft.is_loading = false;
        draft.token = null;
        draft.user = undefined;

        break;
      }
      case SET_LOADING: {
        draft.is_loading = action.payload;
      }
    }
  });
}
