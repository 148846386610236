/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid, Button, FormControlLabel, Switch } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';


import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Container, FormContainer} from './styles';

// import CropDialog from '../../../molecules/crop-dialog';
import TextField from '../../../molecules/textfield';

import {toast} from 'react-toastify';
import PageTitle from '../../../atoms/page-title';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../services/api';
import Loading from '../../../atoms/loading';

const StoreRegisterPage: React.FC = () => {
  // const [showDialog, setShowDialog] = useState(false);

  const [loading, setLoading] = useState(false);

  // const [imageBlob, setImageBlob] = useState();
  // const [imagePreview, setImagePreview] = useState();

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);

  const history = useHistory() as any;
  const params = useParams<{id: string}>();

  const formik = useFormik({
		initialValues: {
      nome: '',
      documento: '',
      telefone: '',
      celular: '',
      endereco: '',
      endereco_numero: '',
      bairro: '',
      cidade: '' as any,
      uf: '' as any,
      cep: '',
      observacao: '',
      categoria: '' as any,
      status: true
    },
		validationSchema: Yup.object().shape({
      nome: Yup.string().required('Campo obrigatório!'),
      documento: Yup.string().required('Campo obrigatório!'),
      telefone: Yup.string().nullable(),
      celular: Yup.string().nullable(),
      endereco: Yup.string().required('Campo obrigatório!'),
      endereco_numero: Yup.string().required('Campo obrigatório!'),
      bairro: Yup.string().nullable(),
      cidade: Yup.string(),
      uf: Yup.string().required('Campo obrigatório!'),
      cep: Yup.string().required('Campo obrigatório!'),
      observacao: Yup.string(),
      status: Yup.bool().required('Campo obrigatório!'),
      categoria: Yup.object().required('Campo obrigatório!')
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const formattedValues = {
        ...values,
        id_cidade: values.cidade.value,
        id_categoria: values.categoria.value
      }
      delete formattedValues.uf;
      delete formattedValues.cidade;

      try {
        if(params.id) {
          await api.put(`/lojas/${params.id}`, formattedValues);
        } else {
          await api.post('/lojas', formattedValues);
        }
        toast.success('Loja salva com sucesso!');
        history.goBack();
      } catch (error) {
        toast.error('Erro ao salvar!');
      }
      setLoading(false);
    }
	});

  // function handleDialogClose() {
  //   setShowDialog(false);
  // }

  // function handleDialogSubmit(blob: any, imagePreview: any) {
  //   if(imagePreview) setImagePreview(imagePreview);
  //   if(blob) setImageBlob(blob)
  //   handleDialogClose();
  // }

  function formikProps(
    fieldName: 'nome' | 'documento' | 'telefone' | 
    'celular' | 'endereco' | 'endereco_numero' | 'bairro' | 'cidade' | 'uf' |
    'cep' | 'observacao' | 'status' | 'categoria'
  ) {
    const error = (formik.touched[fieldName] ? formik.errors[fieldName] : undefined) as string;

    return {
      value: formik.values[fieldName],
      error
    };
  }

  function handleChange(event: any) {
    const value = event?.target.value;
    const field = event?.target.name;

    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  }

  function handleSwitchChange(event: any) {
    const value = event.target.checked;
    const field = event.target.name;

    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  }

  function handleAutocompleteChange(field: string, option: any) {
    const value = option;

    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  } 

  async function requestCategories() {
    try {
      const {data} = await api.get('categorias');
      setCategories(data.map((item: any) => ({
        value: item.id,
        label: item.descricao
      })));
    } catch (error) {
      
    }
  }

  async function requestStates() {
    try {
      const {data} = await api.get('estados');
      setStates(data.map((item: any) => ({
        value: item.id,
        label: item.nome
      })));
    } catch (error) {
      
    }
  } 

  async function requestCities(state_id: number) {
    try {
      const {data} = await api.get(`cidades?id_estado=${state_id}`);
      setCities(data.map((item: any) => ({
        value: item.id,
        label: item.nome,
        state_id: item.id_estado
      })));
    } catch (error) {
      
    }
  }

  async function setInitialValues() {
    if(!params.id) return;
    try {
      const { data } = await api.get(`lojas/${params.id}`)
      formik.setValues({
        ...data,
        cidade: { value: data.cidade.id, label: data.cidade.nome, state_id: data.cidade.id_estado },
        uf: { value: data.cidade.estado.id, label: data.cidade.estado.nome },
        categoria: { value: data.categoria.id, label: data.categoria.descricao }
      });
    } catch (error) {
      
    }
  }

  useEffect(() => {
    if(formik.values.uf?.value) {
      if(formik.values.uf.value !== formik.values.cidade.state_id) {
        formik.setFieldValue('cidade', '');
      }
      requestCities(formik.values.uf.value);
    }
  }, [formik.values.uf])

  useEffect(() => {
    requestStates();
    requestCategories();
    setInitialValues();
  }, [])

  return (
    <>
      <Container>
        <PageTitle label="Loja"/>
        <FormContainer>
          <Grid container spacing={3} justify="flex-end">
            {/* <Grid item xs={12}>
              <ImageContainer>
                <Image src={imagePreview as any} />
                <ImageEditButton onClick={() => {
                  setShowDialog(true)
                }}>Alterar imagem</ImageEditButton>
              </ImageContainer>
            </Grid> */}
            <Grid item xs={12}>
              <TextField 
                name="nome" 
                label="Nome" 
                fullWidth 
                {...formikProps('nome')} 
                onChange={handleChange} 
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={categories}
                getOptionLabel={(option: any) => option && option.label}
                value={formik.values.categoria}
                onChange={(event: any, option: any) => handleAutocompleteChange('categoria', option)}
                renderInput={params => (
                  <TextField 
                    {...params}
                    name="categoria"
                    label="Categoria"
                    fullWidth
                    {...formikProps('categoria')} 
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="documento" 
                label="CNPJ" 
                fullWidth 
                {...formikProps('documento')} 
                onChange={handleChange} 
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="telefone" 
                label="Telefone" 
                fullWidth 
                {...formikProps('telefone')} 
                onChange={handleChange} 
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="celular" 
                label="Celular" 
                fullWidth 
                {...formikProps('celular')} 
                onChange={handleChange} 
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="cep" 
                label="CEP" 
                fullWidth 
                {...formikProps('cep')} 
                onChange={handleChange} 
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="endereco" 
                label="Endereço" 
                fullWidth 
                {...formikProps('endereco')} 
                onChange={handleChange} 
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="endereco_numero" 
                label="Número" 
                fullWidth 
                {...formikProps('endereco_numero')} 
                onChange={handleChange} 
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="bairro" 
                label="Bairro" 
                fullWidth 
                {...formikProps('bairro')} 
                onChange={handleChange} 
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={states}
                getOptionLabel={(option: any) => option && option.label}
                value={formik.values.uf}
                onChange={(event: any, option: any) => handleAutocompleteChange('uf', option)}
                renderInput={params => (
                  <TextField 
                    {...params}
                    name="uf"
                    label="Estado"
                    fullWidth
                    {...formikProps('uf')} 
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={cities}
                getOptionLabel={(option: any) => option && option.label}
                value={formik.values.cidade}
                onChange={(event: any, option: any) => handleAutocompleteChange('cidade', option)}
                renderInput={params => (
                  <TextField 
                    {...params}
                    name="cidade"
                    label="Cidade"
                    fullWidth
                    {...formikProps('cidade')} 
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="observacao" 
                label="Observação" 
                fullWidth 
                {...formikProps('observacao')} 
                onChange={handleChange} 
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel control={
                <Switch 
                  name="status" 
                  {...formikProps('status')} 
                  onChange={handleSwitchChange}
                  checked={formik.values.status}
                />
              } 
              label="Status" />
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => history.goBack()}>
                Voltar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => formik.handleSubmit()} disabled={loading}>
                Enviar
                {loading && <Loading />}
              </Button>
            </Grid>
          </Grid>
        </FormContainer>
      </Container>
    </>
  );
}

export default StoreRegisterPage;