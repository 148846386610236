import React, { useRef, useState } from "react";

import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../../../services/api";
import DeleteDialog from "../../../molecules/delete-dialog";
import MaterialTable from "../../../molecules/table";

const StoreListPage: React.FC = () => {
  const history = useHistory();
  const tableRef = useRef(null) as any;

  const [rowToDelete, setRowToDelete] = useState(0);

  const handleAddClick = () => {
    history.push("/cadastro/loja/");
  };

  const handleEditClick = (event: any, row: any) => {
    history.push(`/cadastro/loja/${row.id}`);
  };

  function filtersToQueryParams(filters: any) {
    const query = filters.map((filter: any) => {
      return `${filter.column.field}=${filter.value}`;
    });
    return query.join("&");
  }

  async function getData(query: any): Promise<any> {
    if (!query) return false;
    let params = "";
    let response;

    if (query.filters.length > 0) {
      params += `${filtersToQueryParams(query.filters)}&`;
    }

    params += `perPage=${query.pageSize}`;
    params += `&currentPage=${query.page + 1}`;

    try {
      response = await api.get(`lojas?${params}`);

      const { page, total } = response.data;

      return {
        data: response.data.data,
        page: page - 1,
        totalCount: Number(total),
      };
    } catch (err) {
      toast.error("Erro");
    }
  }

  function handleOpenDialog(id: any) {
    setRowToDelete(id);
  }

  function handleDialogClose() {
    setRowToDelete(0);
  }

  async function handleDeleteRow() {
    try {
      await api.delete(`lojas/${rowToDelete}`);
      tableRef.current && tableRef.current.onQueryChange();
      handleDialogClose();
    } catch (error) {
      const message =
        error.response?.data?.message || "Erro ao tentar apagar dado!";
      toast.error(message);
    }
  }

  return (
    <Container>
      <>
        <DeleteDialog
          open={Boolean(rowToDelete)}
          onClose={handleDialogClose}
          onSubmit={handleDeleteRow}
        />
        <MaterialTable
          title="Lojas"
          tableRef={tableRef}
          actions={[
            {
              isFreeAction: true,
              icon: "add",
              onClick: handleAddClick,
            },
            {
              icon: "edit",
              onClick: handleEditClick,
            },
            {
              icon: "delete",
              onClick: (_: any, row: any) => handleOpenDialog(row.id),
            },
          ]}
          columns={[
            { title: "Nome", field: "nome" },
            { title: "Categoria", field: "categoria.descricao" },
            { title: "Estado", field: "cidade.estado.nome" },
            { title: "Cidade", field: "cidade.nome" },
            {
              title: "Status",
              field: "status",
              lookup: {
                0: "Inativa",
                1: "Ativa",
              },
            },
          ]}
          getData={(query: any) => getData(query)}
        />
      </>
    </Container>
  );
};

export default StoreListPage;
