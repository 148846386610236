import React from 'react';
import { Button, Grid } from '@material-ui/core';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Form, Image } from './styles';
import TextField from '../../molecules/textfield';

import { signInRequest } from '../../store/modules/auth/actions';
import { RootState } from '../../store';
import Loading from '../../atoms/loading';

const LoginPage: React.FC = () => {
  const is_loading = useSelector((state: RootState) => state.auth.is_loading);
  const dispatch = useDispatch()

  const formik = useFormik({
		initialValues: {
      email: '',
      senha: '',
    },
		validationSchema: Yup.object().shape({
      email: Yup.string().required('Campo obrigatório!').email('Email inválido!'),
      senha: Yup.string().required('Campo obrigatório!').min(6, 'Digite no mínimo 6 caracteres!')
    }),
    onSubmit: (values) => {
      dispatch(signInRequest(values));
    }
	});

  function formikProps(fieldName: 'email' | 'senha') {
    const error = (formik.touched[fieldName] ? formik.errors[fieldName] : undefined) as string;

    return {
      value: formik.values[fieldName],
      error
    };
  }

  function handleChange(event: any) {
    const value = event?.target.value;
    const field = event?.target.name;

    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  }

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <Image src={require('../../assets/images/lagoa_verde.png')} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField name="email" label="EMAIL" fullWidth {...formikProps('email')} onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <TextField name="senha" label="SENHA" type="password" fullWidth {...formikProps('senha')} onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" fullWidth onClick={() => formik.handleSubmit()} disabled={is_loading}>
              Entrar
              {is_loading && (
                <Loading />
              )}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
}

export default LoginPage;