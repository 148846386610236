/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Switch, Redirect } from 'react-router-dom';
import RouteWrapper from '../atoms/route';
import LoginPage from '../pages/login';
import HomePage from '../pages/home';
import StoreRegisterPage from '../pages/store/register';
import ProfilePage from '../pages/profile';
import StoreListPage from '../pages/store/list';
import UserRegisterPage from '../pages/user/register';
import UserListPage from '../pages/user/list';
import AdminRegisterPage from '../pages/admin/register';
import AdminListPage from '../pages/admin/list';
import CheckoutCouponListPage from '../pages/checkoutCoupon/list'
import CouponRegisterPage from '../pages/coupon/register';
import CouponListPage from '../pages/coupon/list';
import BannerRegisterPage from '../pages/banner/register';
import BannerListPage from '../pages/banner/list';
import { setToken } from '../store/modules/auth/actions';
import { useDispatch } from 'react-redux';

const Routes: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setToken())
  }, [])

  
  return (
    <Switch>
      <RouteWrapper path="/login" component={LoginPage} />
      <RouteWrapper path="/home" component={HomePage} isPrivate />
      <RouteWrapper path="/cadastro/loja/:id?" component={StoreRegisterPage} isPrivate />
      <RouteWrapper path="/listagem/lojas" component={StoreListPage} isPrivate />
      <RouteWrapper path="/cadastro/lojista/:id?" component={UserRegisterPage} isPrivate />
      <RouteWrapper path="/listagem/lojistas" component={UserListPage} isPrivate />
      <RouteWrapper path="/cadastro/usuario/:id?" component={AdminRegisterPage} isPrivate />
      <RouteWrapper path="/listagem/usuarios" component={AdminListPage} isPrivate />
      <RouteWrapper path="/cadastro/cupom/:id?" component={CouponRegisterPage} isPrivate />
      <RouteWrapper path="/listagem/cupons" component={CouponListPage} isPrivate />
      <RouteWrapper path="/cadastro/banner/:id?" component={BannerRegisterPage} isPrivate />
      <RouteWrapper path="/listagem/banners" component={BannerListPage} isPrivate />
      <RouteWrapper path="/perfil" component={ProfilePage} isPrivate />
      <RouteWrapper path="/relatorio/baixa_cupons" component={CheckoutCouponListPage} isPrivate />
      <RouteWrapper path="/" component={() => <Redirect to="/home" />} />
    </Switch>
  );
}

export default Routes;